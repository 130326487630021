import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '@containers/layout/layout.container';

const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <div className="container mx-auto flex flex-col items-center justify-center gap-12 w-full h-screen px-4">
        <h1 className="font-helvetica text-3xl lg:text-6xl font-bold text-navy dark:text-light-gray">
          Page not found
        </h1>
        <p className="font-lato text-dark-gray dark:text-gray">
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === 'development' ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
